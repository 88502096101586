import React from 'react';
import story from 'assets/images/Story.jpg';
import './Story.scss';

function Story({ imgRef, activeStoryRef }) {
    return (
        <section className="section story">
            <div className="w-100 content-wrapper">
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="d-flex flex-column h-100 w-100">
                    <div className="w-100 overflow-hidden parallax-image">
                        <img src={story} ref={imgRef} alt="" className="h-100" />
                    </div>
                    <div className="d-none d-lg-block position-absolute top-0 end-0 bottom-0 start-0">
                        <div className="vh-100 position-relative">
                            <div className="active-text p-2 p-lg-3 fw-bold">
                                <div className="text" ref={activeStoryRef}>
                                    Somewhere in Boredtown, a district of Las Vegas, 7 crocs were laying
                                    down under the sun without any adventure listening to how the crypto
                                    millionaires were having crazy fun in the neighborhood.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper mySwiper w-100 story-swiper flex-grow-1 d-lg-none">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <p className="fw-bold p-2 p-md-3">
                                    Somewhere in Boredtown, a district of Las Vegas, 7 crocs were
                                    laying down under the sun without any adventure listening to how
                                    the crypto millionaires were having crazy fun in the
                                    neighborhood.
                                </p>
                            </div>
                            <div className="swiper-slide">
                                <p className="fw-bold p-2 p-md-3">
                                    However, they had their own vibe and vision about the future so
                                    instead of merging with these ones they needed to create their
                                    own.
                                </p>
                            </div>
                            <div className="swiper-slide">
                                <p className="fw-bold p-2 p-md-3">
                                    As every croc knows there cannot be two Las Vegas on Earth. What
                                    was left for them to decide was to select a new planet to
                                    inhabit.{' '}
                                </p>
                            </div>
                            <div className="swiper-slide">
                                <p className="fw-bold p-2 p-md-3">
                                    After some extensive research they found out that planet Mars is
                                    the most credible destination for establishing what is called
                                    Mars Vegas.{' '}
                                </p>
                            </div>
                            <div className="swiper-slide">
                                <p className="fw-bold p-2 p-md-3">
                                    A place where crocs’ race would prosper, benefit and experience
                                    Vegas Vibes on Mars.
                                </p>
                            </div>
                        </div>
                        {/*<div className="swiper-pagination text-start ps-2"></div>*/}
                    </div>
                    {/*<button className="button contain" onClick={handleSlide}>*/}
                    {/*    slide*/}
                    {/*</button>*/}
                </div>
            </div>

            {/*<Swiper slidesPerView={1} className="w-100 story-swiper" spaceBetween={0}>*/}
            {/*    {[1, 2, 3, 4, 5, 6].map((item, index) => (*/}
            {/*        <SwiperSlide key={index}>*/}
            {/*            <img src={logo} alt="" />*/}
            {/*        </SwiperSlide>*/}
            {/*    ))}*/}
            {/*</Swiper>*/}
        </section>
    );
}

export default Story;
