import React, { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import Web3Modal from 'web3modal';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

import WalletConnectProvider from '@walletconnect/web3-provider';
import { INFURA_ID } from '../../common/constants';

function WalletConnectButton() {
    const { activate } = useEthers();
    const [web3Modal, setWeb3Modal] = useState(null);

    useEffect(() => {
        activateProvider();
    }, []);

    const activateProvider = async () => {
        localStorage.removeItem('walletconnect');
        const providerOptions = {
            injected: {
                package: null,
                display: {
                    name: 'Metamask',
                    description: 'Connect with the provider in your Browser',
                },
            },
            coinbasewallet: {
                package: CoinbaseWalletSDK, // Required
                options: {
                    appName: 'Mars Vegas',
                    infuraId: INFURA_ID,
                    darkMode: true,
                },
            },
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    bridge: 'https://bridge.walletconnect.org',
                    infuraId: INFURA_ID,
                },
            },
        };
        const web3Modal = new Web3Modal({
            providerOptions,
            theme: 'dark',
        });
        setWeb3Modal(web3Modal);
        web3Modal.clearCachedProvider();
    };
    const connectWallet = async () => {
        try {
            const provider = await web3Modal?.connect();
            await activate(provider);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <button className="button contain mb-2" onClick={connectWallet}>
            Connect Wallet
        </button>
    );
}

export default WalletConnectButton;
