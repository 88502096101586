import { useCall, useContractFunction } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts';
import { utils } from 'ethers';

export function useMaxTokenPerWallet(contractAbi, contractAddress) {
    const withInterface = new utils.Interface(contractAbi);
    const contract = new Contract(contractAddress, withInterface);
    // return useContractFunction(contract, 'currentMintStatus');
    const call = useCall({
        contract,
        method: 'maxTokenPerWallet',
        args: [],
    });

    if (!call || call.error || !call.value) {
        return;
    }

    return Number(call.value?.[0]);
}
