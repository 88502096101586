import { useCall } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts';
import { utils } from 'ethers';

export function usePrice(contractAbi, contractAddress) {
    const wethInterface = new utils.Interface(contractAbi);
    const contract = new Contract(contractAddress, wethInterface);

    const call = useCall({
        contract,
        method: 'price',
        args: [],
    });

    if (!call || call.error || !call.value) {
        return;
    }

    return {
        price: call.value?.[0],
        error: call.error,
    };
}
