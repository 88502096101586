import React, {useEffect, useLayoutEffect, useRef} from 'react';
import Navigation from './views/layout/Navigation/Navigation';
import Story from './views/sections/Story/Story';
import Header from './views/sections/Header/Header';
import Roadmap from './views/sections/Roadmap/Roadmap';
import Lottie from 'react-lottie';
import animationData from './assets/lottie.json';
import './styles/styles.scss';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-FENJC9Z83N"; // OUR_TRACKING_ID

const navList = [
    {
        id: 'home',
        sectionName: 'Home',
    },
    {
        id: 'story',
        sectionName: 'Story',
    },
    {
        id: 'roadmap',
        sectionName: 'Roadmap',
    },
];

const storyList = [
    'Somewhere in Boredtown, a district of Las Vegas, 7 crocs were\n' +
        '                                    laying down under the sun without any adventure listening to how\n' +
        '                                    the crypto millionaires were having crazy fun in the\n' +
        '                                    neighborhood.',
    'However, they had their own vibe and vision about the future so\n' +
        '                                    instead of merging with these ones they needed to create their\n' +
        '                                    own.',
    'As every croc knows there cannot be two Las Vegas on Earth. What\n' +
        '                                    was left for them to decide was to select a new planet to\n' +
        '                                    inhabit.',
    'After some extensive research they found out that planet Mars is\n' +
        '                                    the most credible destination for establishing what is called\n' +
        '                                    Mars Vegas.',
    'A place where crocs’ race would prosper, benefit and experience\n' +
        '                                    Vegas Vibes on Mars.',
];

function App() {
    const isLoading = false; // TODO remove after data connection

    const imgRef = useRef(null);
    const rocketRef = useRef(null);
    const rocketContainerRef = useRef(null);
    const rocketWayRef = useRef(null);
    const marsRef = useRef(null);

    const handleSlide = (activeIndex) => {
        imgRef.current.style.transform = `translateX(-${
            ((imgRef.current.clientWidth - window.innerWidth) / 4) * activeIndex
        }px)`;
    };

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const activeStoryRef = useRef(null);

    // const { loading: loadingSettings, data: settingsData } = useQuery(GET_SETTINGS_DATA);

    // const { loading: loadingContents, data: contentsData } = useQuery(GET_CONTENTS_LIST);

    const moveRocket = (activeIndex) => {
        let deg = 0;
        rocketWayRef.current.style.opacity = activeIndex === 5 || activeIndex === 6 ? 0 : 1;
        if (activeIndex === 5 || activeIndex === 6) {
            marsRef.current.classList.add('active');
            if (activeIndex === 6) {
                marsRef.current.classList.add('show');
            } else {
                marsRef.current.classList.remove('show');
            }
        } else {
            marsRef.current.classList.remove('active');
            marsRef.current.classList.remove('show');
        }
        switch (activeIndex) {
            case 1: {
                deg = 20;
                break;
            }
            case 2: {
                deg = 45;
                break;
            }
            case 3: {
                deg = 65;

                break;
            }
            case 4: {
                deg = 88;
                break;
            }
            case 5: {
                deg = 110;
                break;
            }
            default: {
                deg = 0;
                break;
            }
        }
        rocketRef.current.style.transform = `rotate(${deg}deg)`;
    };

    // function change() {
    //     elem.classList.add('hide');
    //     setTimeout(function () {
    //         elem.classList.remove('hide');
    //     }, 500);
    // }

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send("pageview");
    }, [])
    useLayoutEffect(() => {
        let timeout;
        // console.log(document.querySelector('.image-container').clientHeight);
        // console.log(document.querySelector('body >div:last-of-type'));
        // rocketContainerRef.current.style.top =
        const storySwiper = new window.Swiper('.story-swiper', {
            effect: 'fade',
            longSwipes: false,
            allowTouchMove: false,
            speed: 400,
        });

        const roadmapSwiper = new window.Swiper('.roadmap-swiper', {
            effect: 'fade',
            longSwipes: false,
            allowTouchMove: false,
            speed: 400,
        });

        new window.fullpage('#myContainer', {
            anchors: ['home', 'story', 'roadmap'],
            menu: '#menu',
            slidesNavigation: true,
            loopHorizontal: false,
            lockAnchors: true,
            navigation: false,
            //In addition to the extension license you'll
            //need to acquire a fullPage.js license from https://goo.gl/5x9a22
            licenseKey: 'FREK9-0OO2I-VHXDI-8EZN9-WQJEK',
            scrollHorizontally: window.innerWidth >= 1024,
            scrollHorizontallyKey:
                'NTNiV0Z5YzNabFoyRnpMbWx2MEVfNjl2YzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1U25P', //see https://goo.gl/xkUmHS
            onSlideLeave: (section, origin, destination, direction, trigger) => {
                if (section.anchor === 'story') {
                    activeStoryRef.current.classList.add('hide');
                    timeout = setTimeout(function () {
                        activeStoryRef.current.innerHTML = storyList[destination.index];
                        activeStoryRef.current.classList.remove('hide');
                    }, 200);
                    storySwiper.slideTo(destination.index);
                    handleSlide(destination.index);
                }
                if (section.anchor === 'roadmap') {
                    roadmapSwiper.slideTo(destination.index);
                    moveRocket(destination.index);
                }
            },
        });

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    ReactGA.initialize(TRACKING_ID);

    return isLoading ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <Lottie options={lottieOptions} height={200} width={200} />
        </div>
    ) : (
        <>
            <Navigation navList={navList} />
            <div id="myContainer">
                <Header />
                <Story imgRef={imgRef} activeStoryRef={activeStoryRef} />

                <Roadmap
                    rocketRef={rocketRef}
                    rocketWayRef={rocketWayRef}
                    marsRef={marsRef}
                    rocketContainerRef={rocketContainerRef}
                />
            </div>
        </>
    );
}

export default App;
