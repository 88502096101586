// import React, { useEffect, useState } from 'react';
// import CrocsImages from 'views/components/CrocsImages/CrocsImages';
// import { useEthers } from '@usedapp/core';
// import './Header.scss';
// import WalletConnectButton from '../../components/ConnectWalletButton';
// import MintButton from '../../components/MintButton';
// import { abiContract } from '../../../abi';
// import { ADDRESS } from '../../../common/constants';
// import { ethers } from 'ethers';
//
// function Header() {
//     const [message, setMessage] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');
//     const [maxTokenPerWallet, setMaxTokenPerWallet] = useState(null);
//     const [status, setStatus] = useState(1);
//     const [loader, setLoader] = useState(false);
//     const { account } = useEthers();
//
//     const [contract, setContract] = useState(null);
//
//     useEffect(() => {
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const signer = provider.getSigner();
//
//         const erc20 = new ethers.Contract(ADDRESS, abiContract, signer);
//
//         setContract(erc20);
//     }, []);
//
//     useEffect(() => {
//         if (contract) {
//             (async () => {
//                 const maxTokenPerWallet = await contract?.maxTokenPerWallet();
//                 const mintStatus = await contract?.mintStatus();
//                 setMaxTokenPerWallet(Number(maxTokenPerWallet));
//                 setStatus(mintStatus);
//                 if (mintStatus === 0 && account) {
//                     setMessage('Mint is currently unavailable.');
//                 }
//             })();
//         }
//         if (!account) {
//             setMessage('');
//             setErrorMessage('');
//         }
//     }, [contract, account]);
//
//     return (
//         <header className='section headerSection'>
//             <div
//                 className='header-container h-100 d-flex px-2 px-md-3 px-lg-5 px-xl-7 px-xxl-10 flex-column justify-lg-between'>
//                 <div className='d-flex flex-column flex-lg-row h-100 align-items-center'>
//                     <div
//                         className='order-1 order-lg-0 align-self-lg-end d-flex justify-content-center align-items-end crocs-container'>
//                         <CrocsImages ms={1200} />
//                     </div>
//                     <div
//                         className='order-0 order-lg-1 flex-grow-1 ps-lg-5 ps-xl-7 pt-lg-1 pt-xl-0 pt-xxl-7 content-container align-self-lg-start'>
//                         {account ? (
//                             <div className='mb-3 mb-md-3'>
//                                 <h1 className='fw-bold mb-05'>You are now one button away from</h1>
//                                 <h1 className='fw-bold mb-05'>MARS VEGAS</h1>
//                                 {status !== 0 && (
//                                     <span className='subtitle d-block mt-md-2'>
//                                         You can only mint {maxTokenPerWallet} NFT
//                                     </span>
//                                 )}
//                             </div>
//                         ) : (
//                             <>
//                                 <div className='mb-1 mb-md-3'>
//                                     <h1 className='fw-bold mb-05'>7000 free tickets to Mars</h1>
//                                     <h1 className='fw-bold mb-1 mb-md-2'>
//                                         777 Mars Vegas DAO owners
//                                     </h1>
//                                     <span className='subtitle d-block'>
//                                         Flight days to be announced
//                                     </span>
//                                     <span className='subtitle d-block'>
//                                         Alpha tickets prices to be announced
//                                     </span>
//                                 </div>
//
//                                 <div className='d-flex crocs-count py-2 py-xxl-3 mb-2 mb-md-4'>
//                                     <div className='px-1 text-center flex-grow-1'>
//                                         <h4 className='fw-bold mb-xxl-2'>7000</h4>
//                                         <p className='fw-bold'>Martian crocs</p>
//                                     </div>
//                                     <div className='px-1 text-center flex-grow-1'>
//                                         <h4 className='fw-bold mb-xxl-2'>777</h4>
//                                         <p className='fw-bold'>Alpha crocs</p>
//                                     </div>
//                                     <div className='px-1 text-center flex-grow-1'>
//                                         <h4 className='fw-bold mb-xxl-2'>2222</h4>
//                                         <p className='fw-bold'>Mutated crocs</p>
//                                     </div>
//                                 </div>
//                             </>
//                         )}
//
//                         {account ? (
//                             <MintButton
//                                 setMessage={setMessage}
//                                 setErrorMessage={setErrorMessage}
//                                 status={status}
//                                 loader={loader}
//                                 setLoader={setLoader}
//                                 account={account}
//                             />
//                         ) : (
//                             <WalletConnectButton setErrorMessage={setErrorMessage} />
//                         )}
//
//                         {errorMessage && <div className='mb-2'>{errorMessage}</div>}
//                         {message && <div className='mb-2'>{message}</div>}
//                     </div>
//                 </div>
//             </div>
//         </header>
//     );
// }
//
// export default Header;


import React, { useEffect, useState } from 'react';
import CrocsImages from 'views/components/CrocsImages/CrocsImages';
import {Mainnet, useEthers} from '@usedapp/core';
import './Header.scss';
import WalletConnectButton from '../../components/ConnectWalletButton';
import MintButton from '../../components/MintButton';
import { abiContract } from '../../../abi';
import { ADDRESS } from '../../../common/constants';
import { ethers } from 'ethers';
import { useMintStatus } from '../../../hooks/useMintStatus';
import { useMaxTokenPerWallet } from '../../../hooks/useMaxTokenPerWallet';

function Header() {
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const { account, chainId } = useEthers();

    const mintStatus = useMintStatus(abiContract, ADDRESS);
    const maxTokenPerWallet = useMaxTokenPerWallet(abiContract, ADDRESS);
    useEffect(() => {
        if (chainId !== Mainnet.chainId) {
            setMessage("Please choose Mainnet network")
        }
    }, [chainId])
    useEffect(() => {
        if (mintStatus) {
            if (mintStatus === 0 && account) {
                setMessage('Mint is currently unavailable.');
            }
        }

        if (!account) {
            setMessage('');
            setErrorMessage('');
        }
    }, [account, mintStatus]);

    return (
        <header className='section headerSection'>
            <div
                className='header-container h-100 d-flex px-2 px-md-3 px-lg-5 px-xl-7 px-xxl-10 flex-column justify-lg-between'>
                <div className='d-flex flex-column flex-lg-row h-100 align-items-center'>
                    <div
                        className='order-1 order-lg-0 align-self-lg-end d-flex justify-content-center align-items-end crocs-container'>
                        <CrocsImages ms={1200} />
                    </div>
                    <div
                        className='order-0 order-lg-1 flex-grow-1 ps-lg-5 ps-xl-7 pt-lg-1 pt-xl-0 pt-xxl-7 content-container align-self-lg-start'>
                        {account ? (
                            <div className='mb-3 mb-md-3'>
                                <h1 className='fw-bold mb-05'>You are now one button away from</h1>
                                <h1 className='fw-bold mb-05'>MARS VEGAS</h1>
                                {mintStatus !== 0 && (
                                    <span className='subtitle d-block mt-md-2'>
                                        You can only mint {maxTokenPerWallet} NFT
                                    </span>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className='mb-1 mb-md-3'>
                                    <h1 className='fw-bold mb-05'>7000 free tickets to Mars</h1>
                                    <h1 className='fw-bold mb-1 mb-md-2'>
                                        777 Mars Vegas DAO owners
                                    </h1>
                                    {/*<span className='subtitle d-block'>*/}
                                    {/*    Flight days to be announced*/}
                                    {/*</span>*/}
                                    {/*<span className='subtitle d-block'>*/}
                                    {/*    Alpha tickets prices to be announced*/}
                                    {/*</span>*/}
                                    <span className='subtitle d-block fw-bold'>
                                        Minting is open!
                                    </span>
                                </div>

                                <div className='d-flex crocs-count py-2 py-xxl-3 mb-2 mb-md-4'>
                                    <div className='px-1 text-center flex-grow-1'>
                                        <h4 className='fw-bold mb-xxl-2'>7000</h4>
                                        <p className='fw-bold'>Martian crocs</p>
                                    </div>
                                    <div className='px-1 text-center flex-grow-1'>
                                        <h4 className='fw-bold mb-xxl-2'>777</h4>
                                        <p className='fw-bold'>Alpha crocs</p>
                                    </div>
                                    <div className='px-1 text-center flex-grow-1'>
                                        <h4 className='fw-bold mb-xxl-2'>2222</h4>
                                        <p className='fw-bold'>Mutated crocs</p>
                                    </div>
                                </div>
                            </>
                        )}

                        {account ? (
                            <MintButton
                                setMessage={setMessage}
                                setErrorMessage={setErrorMessage}
                                status={mintStatus}
                                loader={loader}
                                setLoader={setLoader}
                                account={account}
                            />
                        ) : (
                            <WalletConnectButton setErrorMessage={setErrorMessage} />
                        )}

                        {errorMessage && <div className='mb-2'>{errorMessage}</div>}
                        {message && <div className='mb-2'>{message}</div>}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
