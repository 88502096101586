import React from 'react';
import roadmap_mobile1 from 'assets/images/roadmap-m-1.png';
import roadmap_mobile2 from 'assets/images/roadmap-m-2.png';
import roadmap_mobile3 from 'assets/images/roadmap-m-3.png';
import roadmap_mobile4 from 'assets/images/roadmap-m-4.png';
import roadmap_mobile5 from 'assets/images/roadmap-m-5.png';
import roadmap_mobile6 from 'assets/images/roadmap-m-6.png';
import roadmap_desktop1 from 'assets/images/roadmap-d-1.png';
import roadmap_desktop2 from 'assets/images/roadmap-d-2.png';
import roadmap_desktop3 from 'assets/images/roadmap-d-3.png';
import roadmap_desktop4 from 'assets/images/roadmap-d-4.png';
import roadmap_desktop5 from 'assets/images/roadmap-d-5.png';
import roadmap_desktop6 from 'assets/images/roadmap-d-6.png';

import building1 from 'assets/images/1.svg';
import building2 from 'assets/images/2.svg';
import building3 from 'assets/images/3.svg';
import building4 from 'assets/images/4.svg';

import TravelToMars from './TravelToMars';
import mars from 'assets/images/mars.svg';
import './Roadmap.scss';
import nft_calendar from '../../../assets/images/nft_calendar.svg';

function Roadmap({ rocketRef, rocketWayRef, marsRef, rocketContainerRef }) {
    const mock = [
        {
            imgM: roadmap_mobile1,
            imgD: roadmap_desktop1,
            roadmapStory: 'Alpha crocs send lucky Martian crocs to inhabit Mars',
            roadmap: 'Free Mints for the first 7000 NFTs. ',
        },
        {
            imgM: roadmap_mobile2,
            imgD: roadmap_desktop2,
            roadmapStory: 'Alpha crocs move to Mars to build Mars Vegas',
            roadmap: '777 Alpha NFTs for sale with more benefits and authority in Mars Vegas. ',
        },
        {
            imgM: roadmap_mobile3,
            imgD: roadmap_desktop3,
            roadmapStory: 'Alpha crocs undertake the creation of Mars Vegas',
            roadmap:
                'Mars Vegas DAO is created where the Alpha NFT holders become the owners with voting rights.',
        },
        {
            imgM: roadmap_mobile4,
            imgD: roadmap_desktop4,
            roadmapStory:
                'Years pass benefiting crocs living on Mars but exhausting life on Earth. Connection with planet Earth is established',
            roadmap: 'New project creation and marketing.',
        },
        {
            imgM: roadmap_mobile5,
            imgD: roadmap_desktop5,
            roadmapStory:
                'Tickets are sold to the last living, already mutated, crocs on planet Earth to move to Mars Vegas and live the vibe',
            roadmap:
                '2222 mutated Alpha croc NFTs are issued by DAO to raise funds for first P2E game creation.',
        },
        {
            imgM: roadmap_mobile6,
            imgD: roadmap_desktop6,
            roadmapStory: 'Mutated crocs leave the planet Earth to reside in Mars Vegas',
            roadmap: '2222 Alpha NFTs are sold out',
        },
    ];

    return (
        <section className="section roadmap">
            <div className="w-100 content-wrapper">
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="slide"></div>
                <div className="swiper h-100 w-100 mySwiper roadmap-swiper position-relative flex-grow-1 mb-2">
                    <div className="swiper-wrapper">
                        {mock.map((item) => {
                            return (
                                <div className="swiper-slide" key={item.imgD}>
                                    <div className="row g-0 content-container">
                                        <div
                                            className="col-lg-6 image"
                                            style={
                                                window.innerWidth < 1024
                                                    ? {
                                                          height: `${
                                                              (window.innerHeight - 104) / 2
                                                          }px`,
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div
                                                className="image-container h-100 position-relative d-flex align-items-lg-end"
                                                style={{
                                                    backgroundImage: `url(${item.imgD})`,
                                                }}
                                            >
                                                {/*<img*/}
                                                {/*    src={item.imgM}*/}
                                                {/*    alt="roadmap1"*/}
                                                {/*    className="d-lg-none w-100"*/}
                                                {/*/>*/}
                                                <p className="p-2 p-md-3 fw-medium roadmap-story fw-medium">
                                                    {item.roadmapStory}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="fw-bold mb-1 roadmap-content">
                                                {item.roadmap}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="swiper-slide">
                            <div className="row vh-100 g-0">
                                <div className="col-lg-6 d-flex align-items-lg-center">
                                    <div className="last-slide px-2 px-md-3 px-lg-5 px-xl-7 px-xxl-10">
                                        <div className="fw-bold mb-1 mb-lg-2">
                                            Finally, it's time to build the vital infrastructures
                                            and start realizing the dreams of so many rescued crocs
                                        </div>
                                        <span className="fw-medium mb-2 d-block">
                                            Project organizers suggest 2 P2E game concepts to Alpha
                                            crocs who have voting power in DAO. Based on the voting
                                            results one of the concepts is confirmed and funded in
                                            milestones suggested by project organizers.
                                        </span>
                                        <p className="mb-1 mb-md-3">
                                            <span className="subtitle d-inline-block">
                                                As seen on
                                            </span>
                                            <a
                                                target="_blank"
                                                className="nft_calendar"
                                                href="https://nftcalendar.io/"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    className="d-inline-block ps-2"
                                                    src={nft_calendar}
                                                    alt="NFT_Calendar"
                                                />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="swiper-pagination text-start ps-2"></div>*/}
                </div>
                <div
                    className="position-absolute travel-container"
                    style={
                        window.innerWidth < 1024
                            ? { height: `${(window.innerHeight - 44) / 2}px` }
                            : {}
                    }
                >
                    <div className="svg-container" ref={rocketContainerRef}>
                        <TravelToMars rocketRef={rocketRef} rocketWayRef={rocketWayRef} />
                        <div className="mars-container position-absolute" ref={marsRef}>
                            <img src={mars} alt="mars" className="w-100 mars position-relative" />
                            <img
                                src={building1}
                                alt="building1"
                                className="position-absolute building building1"
                            />
                            <img
                                src={building2}
                                alt="building2"
                                className="position-absolute building building2"
                            />
                            <img
                                src={building3}
                                alt="building3"
                                className="position-absolute building building3"
                            />
                            <img
                                src={building4}
                                alt="building4"
                                className="position-absolute building building4"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Roadmap;
