import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {DAppProvider, Goerli, Mainnet} from '@usedapp/core';
import {INFURA_ID} from './common/constants';
import {AppProvider} from './AppContext';

const config = {
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
        [Mainnet.chainId]: `https://mainnet.infura.io/v3/${INFURA_ID}`,
        [Goerli.chainId]: `https://goerli.infura.io/v3/${INFURA_ID}`,
    },
    multicallVersion: 2,
    fastMulticallEncoding: true,
    noMetamaskDeactivate: true,
};

ReactDOM.render(
    <React.StrictMode>
        <DAppProvider config={config}>
            <AppProvider>
                <App/>
            </AppProvider>
        </DAppProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
