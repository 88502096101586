import React, {useEffect} from 'react';
import {ADDRESS} from '../../common/constants';
import {abiContract} from '../../abi';
import {ethers, utils} from 'ethers';
import axios from 'axios';
import {useContractFunction, useEthers} from "@usedapp/core";
import {usePrice} from "../../hooks/usePrice";

const errors = {
    WALLET_LIMIT_EXCEEDED: 'Cannot mint more then 1 CROC',
    INVALID_MERKLE_PROOF: 'You are not whitelisted',
    SALE_CLOSED: 'Sale is closed',
};

const sanitizeError = (message) => {
    const code = message.replace('execution reverted: ', '');

    return errors[code] || code;
};


export function MintButton({setMessage, setErrorMessage, status, setLoader, loader, account}) {
    const wethInterface = new utils.Interface(abiContract)

    const contract = new ethers.Contract(ADDRESS, wethInterface);

    const priceResponse = usePrice(abiContract, ADDRESS);

    const {send: saleMint, state: saleState} = useContractFunction(contract, 'mint',);
    const {send: presaleMint, state: presaleState} = useContractFunction(contract, 'presaleMint',);
    const {send: freeMint, state: freeState} = useContractFunction(contract, 'freeMint',);

    useEffect(() => {
        if (saleState.errorMessage) {
            setErrorMessage(sanitizeError(saleState.errorMessage));
        } else if (presaleState.errorMessage) {
            setErrorMessage(sanitizeError(presaleState.errorMessage));
        } else if (freeState.errorMessage) {
            setErrorMessage(sanitizeError(freeState.errorMessage));
        }
    }, [saleState.errorMessage, presaleState.errorMessage, freeState.errorMessage]);

    useEffect(() => {
        if (saleState.receipt || presaleState.receipt || freeState.receipt) {
            setMessage('You Successfully minted 1 CROC!!');
        }
    }, [saleState.receipt, presaleState.receipt, freeState.receipt]);

    const mintNFT = async () => {
        setMessage('');
        setErrorMessage('');
        setLoader(true);

        switch (status) {
            case 1: {
                await freeMint();
                setLoader(false);
                break;
            }
            case 2: {
                axios
                    .get(`https://mars-vegas-backend.herokuapp.com/wallets/${account}/proof`)
                    .then(async ({data}) => {
                        if (!data.verifiedAddress) {
                            return setMessage('You are not whitelisted!');
                        }
                        await presaleMint();
                        setLoader(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoader(false);
                        setMessage('Something went wrong. Please try later.');
                    });
                break;
            }
            case 3: {
                await saleMint({ value: priceResponse.price.toString() })
                setLoader(false);
            }
        }
    };

    return (
        <div className="position-relative">
            <button
                disabled={loader || status === 0}
                className="button contain mb-2"
                onClick={status === 0 ? () => {} : mintNFT}
            >
                {loader ? 'Please wait...' : 'Mint'}
            </button>
        </div>
    );
}

export default MintButton;
