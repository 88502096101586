import React, { useState } from 'react';

const AppContext = React.createContext();

export function AppProvider({ children, settings }) {
    const [currentSettings, setCurrentSettings] = useState(settings || {});

    const saveSettings = (values) => {
        setCurrentSettings(values);
    };

    return (
        <AppContext.Provider
            value={{
                settings: currentSettings,
                saveSettings,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export default AppContext;
