import { useState, useEffect } from 'react';
import { crocsArr } from 'assets/crocs';

export default function CrocsImages({ ms }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        let i = setTimeout(function Change() {
            if (currentImageIndex === crocsArr.length - 1) {
                setCurrentImageIndex(0);
            } else {
                setCurrentImageIndex(currentImageIndex + 1);
            }
        }, ms);

        return () => {
            clearTimeout(i);
        };
    }, [currentImageIndex]);

    return (
        <div className="order-1 crocs-slide">
            <img src={crocsArr[currentImageIndex]} className="w-100" alt="croc_image" />
        </div>
    );
}
